import React from 'react';
import styled from 'styled-components';
import { Column, Row } from './defaults';
import { BmrIcon } from '../images/bmr-icon.js';
import imgShare from '../images/img-share.svg';

const ImageWrapper = styled(Column)`
    height: 500px;
    width: 500px;
    background: rgba(2, 17, 39, 1);
    border-radius: 20px;
    position: relative;
    overflow: hidden;
`;

const BackgroundImg = styled.img`
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
`;

const ImageTitle = styled.div`
    color: white;
    font-size: 28px;
    line-height: 28px;
    font-family: 'Satoshi-Medium';
    margin-top: 100px;
`;

const ImageBmrWrapper = styled(Row)`
    margin-top: 28px;
    margin-bottom: 20px;
`;

const BmrText = styled.div`
    background: -webkit-linear-gradient(180deg, #FFFFFF 17.8%, #A7AFFF 86.6%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`;

const BMR = styled(BmrText)`
    color: white;
    font-size: 132px;
    line-height: 130px;
    margin-right: 5px;
    font-family: 'Satoshi-Regular';
`;

const BmrImageWrapper = styled.div`
    transform: scale(0.7);
    transform-origin: top left;
`;

const Container = styled.div`
    height: 350px;
    width: 350px;
    overflow: hidden;
`;

const Contents = styled(Column)`
    align-items: center;
    z-index: 1000;
`;

const Comparison = styled.div`
    color: white;
    font-size: 32px;
    line-height: 32px;
    font-family: 'Satoshi-Medium';
`;

const FooterSpan = styled.span`
    color: white;
    font-size: 20px;
    line-height: 20px;
    font-family: 'Satoshi-Bold';
    margin-top: 15px;
`

export const BmrImage = React.forwardRef(({bmrResult}, ref) => {
    return (
        <Container>
        <BmrImageWrapper>
            <ImageWrapper>
                <Contents>
                    {/* <BmrIcon width={100} fill='white' /> */}
                    <ImageTitle>My body burns</ImageTitle>
                    <ImageBmrWrapper>
                        <BMR>{bmrResult}</BMR>
                    </ImageBmrWrapper>
                    <Comparison>calories at rest</Comparison>
                    <FooterSpan>Discovery yours here!</FooterSpan>
                </Contents>
                <BackgroundImg src={imgShare} />
            </ImageWrapper>
        </BmrImageWrapper>
        </Container>
    );
})

