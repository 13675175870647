import React, { useEffect, useRef, useState } from 'react';
import api from '../utils/api';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import { AuthBox } from './auth-box';

import { 
    Error, 
    ExtraLink, 
    Form, 
    Input, 
    SubmitButton, 
    Title 
} from '../components/auth-components';
import { 
    getAuth, 
    signInWithEmailAndPassword, 
    browserLocalPersistence, 
    setPersistence 
} from 'firebase/auth';
import { FlexRow } from '../components/defaults';
import { NotificationManager } from 'react-notifications';


export const SignIn = () => {

    const notificationCount = useRef(0);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const pwRef = useRef();
    const navigate = useNavigate();
    const params = useParams();
    const [searchParams] = useSearchParams();

    const query = new URLSearchParams(window.location.search);
    const didRedirectFromBrand = query.get('redirect_from_brand');
    
    useEffect(() => {
        if (didRedirectFromBrand && notificationCount.current < 1) {
            NotificationManager.warning('Please sign in to TrumeLabs to continue', 'We detected multiple brands associated with your account.')
            notificationCount.current += 1;
        }
    }, [didRedirectFromBrand])

    const submit = async () => {
        if (loading || !email || !password) return;
        setLoading(true);
        setError('');
        
        try {
            await setPersistence(getAuth(), browserLocalPersistence);
            await signInWithEmailAndPassword(getAuth(), email, password);
            const token = await getAuth().currentUser.getIdToken();
            localStorage.setItem('token', token);
            if (params?.barcode) navigate(`/dashboard/register-kit/${params.barcode}`)
            console.log(searchParams.get('redirect_lead_page'))
            if (searchParams.get('redirect_lead_page')) navigate(searchParams.get('redirect_lead_page'))
        } catch (err) {
            try {
                await (await api.get(`/legacy?value=${email}`)).data;
                navigate('/sign-in/reset-pw-instructions');
            } catch (err) {
                //console.log('not an user', err);
            }

            if (err.message === 'Firebase: Error (auth/user-not-found).') setError('There was an error, please try another email or password.')
            else if (err.message === 'INVALID_LOGIN_CREDENTIALS') setError('There was an error, please try another email or password.')
            else setError('There was an error, please try again or contact support.')
        }
        setLoading(false);
    }


    return <AuthBox>
        <Form>
            <Title>Login to your account</Title>
            <Input 
                value={email}
                type='email'
                nextRef={pwRef}
                onChange={(e) => setEmail(e.target.value)}
                style={{ marginBottom: 20, marginTop: 10 }} 
                placeholder='Your Email'/>
            <Input 
                value={password} 
                onChange={(e) => setPassword(e.target.value)}
                ref={pwRef} 
                style={{ marginBottom: 20 }} 
                type='password' 
                nextAction={submit}
                placeholder='Your Password'/>
            {error && <Error>{error}</Error>}
            <SubmitButton onClick={submit} disabled={loading || !password || !email}>
                {loading ? <div>Signing In</div> : <div>Sign In</div>}
            </SubmitButton>
            <FlexRow style={{ justifyContent: 'space-between', marginTop: 15 }}>
                <ExtraLink href='/sign-up/1'>Create your account</ExtraLink>
                <ExtraLink href='/sign-in/recover-pw'>Forgot Password</ExtraLink>
            </FlexRow>
        </Form>
    </AuthBox>
  
}