import { useState } from 'react';
import { OnboardingForm } from '../components/onboarding-form.js';
import LandingPageFooter from '../components/landing-page-footer.js';
import { ABSI_CALCULATION_FORM } from '../constants/absi-calculation-form.js';
import { ShareBanner } from '../components/share-banner.js';
import { useNavigate } from 'react-router-dom';
import { AbsiShareImage } from '../components/absi-share-image.js';

export const AbsiCalculation = () => {

  const [shareBannerVisible, setShareBannerVisible] = useState(false);
  const navigate = useNavigate();

  const onEndForm = (answers) => {
    sessionStorage.setItem('absiFormAnswers', JSON.stringify(answers))
    navigate('/absi-calculation/form/2', { replace: true });
  }

  return (<div>
      <ShareBanner 
        title='Discover your Longevity Score'
        text="Discover your longevity score with our A Body Shape Index (ABSI) Calculator!"
        url="https://trumelabs.com/calculate-my-absi"
        image={<AbsiShareImage />}
        visible={shareBannerVisible} 
        setVisible={setShareBannerVisible}
      />
      <OnboardingForm 
        setShareBannerVisible={setShareBannerVisible}
        footer={<LandingPageFooter
          openShareModal={() => setShareBannerVisible(true)}
          title="Share the Longevity Score Calculator"
          subTitle=''
          description='Discover your friends Longevity Score with our A Body Shape Index (ABSI) Calculator!'
          shareButtonText='Share With Loved Ones'
          />
        }
        fields={ABSI_CALCULATION_FORM}
        onEndForm={onEndForm}
      />
    </div>
  )
}