import React, { useState, useEffect } from 'react';
import { ProfileIcon } from '../images/profile-icon.js';
import { SignoutIcon } from '../images/signout-icon.js';
import closeIcon from '../images/close.svg';
import menuIcon from '../images/menu.svg';
import styled from 'styled-components';
import { signOut, getAuth } from 'firebase/auth';
import { useDispatch } from 'react-redux';
import { clear } from '../redux/reducer/user';
import { useNavigate } from 'react-router-dom';
import { Column } from './defaults';

const Wrapper = styled.div`
    position: relative;
    z-index: 99999;
`;

const ProfileIconWrapper = styled.div`
    height: 30px;
    width: 30px;
    cursor: pointer;
    background: #F1F3F7;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 1100px) {
        display: none;
    }
`;

const MenuIcon = styled.img`
    height: 30px;
    width: 30px;
    cursor: pointer;
    display: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);

    @media (max-width: 762px) {
        display: block;
    }
`;

const Ribbon = styled(Column)`
    position: absolute;
    right: 0px;
    top: 35px;
    border-radius: 20px;
    background: white;
    box-shadow: 1px 1px 5px rgba(0,0,0,.5);
    z-index: 100;
    padding: 20px;
    gap: 10px;
    width: 140px;
`;

const MobileMenuWrapper = styled(Column)`
    display: none;
    @media (max-width: 762px) {
        display: block;
        position: fixed;
        height: 100vh;
        width: 100%;
        z-index: 9999;
        left: 0;
        top: 70px;
        background-color: #fff;
        border-top: 1px solid #D9D9D9;
        padding: 32px 24px;
        overflow: hidden;
        transition: 0.5s;
    }
`;

const Mask = styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 99;
    background: rgba(0,0,0,.3);
`;

const Item = styled.div`
    white-space: nowrap;
    cursor: pointer;
    
`;

const MobileMenu = styled.div`
    display: flex;
    flex-direction: column;
    gap: 45px;
    height: 100vh;
`

const MobileMenuItem = styled.a`
    display: flex;
    flex-direction: row;
    gap: 6px;
    text-decoration: none;
    color: #5B637D;
    text-align: center;
    font-family: 'Satoshi-Medium';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.26px;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
`

const ItemIcon = styled.div`
`

export const UserRibbon = ({ menuItems }) => {

    const [ribbonVisible, setRibbonVisible] = useState(false);
    const [mobileMenu, setMobileMenu] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onClickSignOut = async () => {
        await signOut(getAuth())
        localStorage.removeItem('token');
        dispatch(clear());
    }

    const onClickProfile = async () => {
        setMobileMenu(false)
        navigate('/dashboard/profile');
        setRibbonVisible(false)
    }

    useEffect(() => {
        if (mobileMenu) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
        return () => {
            document.body.style.overflow = 'auto'; // Resetar ao desmontar
        };
    }, [mobileMenu]);


    return <Wrapper>
        <ProfileIconWrapper onClick={() => setRibbonVisible(true)}>
            <ProfileIcon fill="#4F59F0" widht="34" height='35' />
        </ProfileIconWrapper>
        <MenuIcon src={!mobileMenu ? menuIcon : closeIcon} onClick={() => setMobileMenu(previous => !previous)} />
        {ribbonVisible && <Mask onClick={() => setRibbonVisible(false)} />}
        {ribbonVisible && <Ribbon>
            <Item onClick={onClickProfile}>Profile</Item>
            <Item onClick={onClickSignOut}>Sign Out</Item>
        </Ribbon>}
        {mobileMenu && <MobileMenuWrapper>
            <MobileMenu>
                {menuItems.map(item => item.showItem && 
                    (<MobileMenuItem href={item.url}>
                        <ItemIcon>
                            {item.iconComponent}
                        </ItemIcon>
                        {item.name}
                    </MobileMenuItem>)
                )}
                <MobileMenuItem onClick={onClickProfile}>
                    <ProfileIcon fill='#5B637D' />
                    Profile
                </MobileMenuItem>
                <MobileMenuItem onClick={onClickSignOut}>
                    <SignoutIcon fill='#5B637D' />
                    Sign Out
                </MobileMenuItem>
            </MobileMenu>
        </MobileMenuWrapper>}
    </Wrapper>

}