/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react"
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import loadingIcon from '../images/3-dots-move-purple.svg'
import api from "../utils/api";
import { useNavigate } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import useAuth from "../utils/useAuth";


export const ReuseKit = () => {

    const { isLoading, currentUser } = useAuth();
    const { barcode } = useParams();
    const navigate = useNavigate()

    const getRedirect = async () => {
        try {
            const result = await api.get(`/kit/reuse?barcode=${barcode}`);
            console.log(result?.data)
            window.location.href = result.data.url;
        } catch (err) {
            navigate('/dashboard')
            setTimeout(() => NotificationManager.error('If you received this link through your email, please call support.', 'Invalid link'), 100)
        }
    }
    
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (!isLoading && currentUser) {
            getRedirect()
        }
    }, [isLoading])

    return <div className="h-screen flex items-center justify-center">
        <img src={loadingIcon} alt='carregando' className="h-[30px]" />
    </div>

}