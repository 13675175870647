import { useRef } from "react"
import { useLocation } from 'react-router-dom';

import LandingPageNavbar from "../components/landing-page-navbar.js"
import { BiologicalAgeTestTutorial } from "../components/kit-submission-tutorial/biological-age.js"
import { DNAMethylationTestTestTutorial } from "../components/kit-submission-tutorial/dna-methylation.js"
import useAuth from '../utils/useAuth';



const testTypeTutorial = {
  'biological-age-test': ({ nextStep, divRefs, isUserLoggedIn }) => (
  <BiologicalAgeTestTutorial nextStep={nextStep} divRefs={divRefs} isUserLoggedIn={isUserLoggedIn} />
),
  'dna-methylation': ({ nextStep, divRefs, isUserLoggedIn }) => (
  <DNAMethylationTestTestTutorial nextStep={nextStep} divRefs={divRefs} isUserLoggedIn={isUserLoggedIn} />
)}

export const KitSubmissionTutorial = () => {
  const location = useLocation()
  const divRefs = useRef([])
  const { currentUser } = useAuth();

  const testType = location.pathname.split('/')[2]

  const nextStep = (index) => {
     if (divRefs.current[index]) {
      divRefs.current[index].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }

  if (!testTypeTutorial[testType]) return
  const tutorial = testTypeTutorial[testType]({
    nextStep,
    divRefs,
    isUserLoggedIn: !!currentUser
  })

  return (
    <>
      <LandingPageNavbar />
      {tutorial}
    </>
  )
}