import { getDateFormat, PLACEHOLDER_FORMAT } from "../utils/date"

const DATE_FORMAT = getDateFormat();

const PLACEHOLDER_DATE_FORMAT = getDateFormat(PLACEHOLDER_FORMAT);

export const ABSI_CALCULATION_FORM = [
    {
      description: 'Tell us a little bit about yourself',
      question: 'My birthday is',
      type: 'date',
      dateFormat: DATE_FORMAT,
      placeholder: PLACEHOLDER_DATE_FORMAT,
      name: 'date_of_birth',
      inputMode: 'numeric',
      askOnlyOnce: true,
    },
    {
      description: 'Tell us a little bit about yourself',
      question: 'What is your biological sex?',
      type: 'dropdown',
      name: 'biological_sex',
      askOnlyOnce: true,
      options: [
        { label: 'Female', value: 'Female' },
        { label: 'Male', value: 'Male' },
        { label: 'Other', value: 'Other' }
      ]
    },
    {
      description: 'Tell us a little bit about yourself',
      question: 'My height is',
      type: 'dropbox',
      name: 'height',
      renderInternalQuestion: true,
      inputMode: 'numeric',
      askOnlyOnce: true,
      fieldNames: ['height_feet', 'height_inches','height_centimeter'],
      options: [
        {
          label: 'ft.',
          inputs: [
            {
              placeholder: '00',
              legend: 'ft',
              name: 'height_feet',
              inputMode: 'numeric',
              type: 'number',
              minValue: 0
            },
            {
              placeholder: '00',
              legend: 'in',
              name: 'height_inches',
              inputMode: 'numeric',
              type: 'number',
              minValue: 0
            },
          ]
        },
        {
          label: 'cm',
          inputs: [
            {
              placeholder: '00',
              legend: 'cm',
              name: 'height_centimeter',
              inputMode: 'numeric',
              type: 'number',
            }
          ]
        }
      ]
    },
    {
      description: 'Tell us a little bit about yourself',
      question: 'My waist measures',
      type: 'dropbox',
      name: 'waist',
      renderInternalQuestion: true,
      inputMode: 'numeric',
      options: [
        {
          label: 'in.',
          inputs: [
            {
              placeholder: '00',
              legend: 'in',
              name: 'waist_inches',
              inputMode: 'numeric',
              type: 'number',
              minValue: 0
            },
          ]
        },
        {
          label: 'cm',
          inputs: [
            {
              placeholder: '00',
              legend: 'cm',
              name: 'waist_centimeter',
              inputMode: 'numeric',
              type: 'number',
            }
          ]
        }
      ]
    },
    {
      description: 'Tell us a little bit about yourself',
      question: 'I weigh',
      type: 'dropbox',
      name: 'weight',
      renderInternalQuestion: true,
      inputMode: 'numeric',
      askOnlyOnce: true,
      fieldNames: ['weight_pounds', 'weight_kilos'],
      options: [
        {
          label: 'lbs.',
          inputs: [
            {
              placeholder: '0',
              legend: 'lbs',
              name: 'weight_pounds',
              inputMode: 'numeric',
              type: 'number',
            },
          ]
        },
        {
          label: 'kg',
          inputs: [
            {
              placeholder: '0',
              legend: 'kg',
              name: 'weight_kilos',
              inputMode: 'numeric',
              type: 'number',
            }
          ]
        }
      ]
    }
]