import React from 'react';
import styled from 'styled-components';
import { Column } from './defaults';
import absiShareImage from '../images/absi-share-image.svg';

const ImageWrapper = styled(Column)`
    background: transparent;
    position: relative;
`;

const Image = styled.img`
    width: 350px;
    height: 350px;
`;

export const AbsiShareImage = () => {
    return <ImageWrapper>
        <Image src={absiShareImage} />
    </ImageWrapper>

};