/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Column, FlexRow, Row } from '../components/defaults';
import styled from 'styled-components';
import arrow from '../images/arrow-navy.svg';
import shareIcon from '../images/share.svg';
import biologicalAgeGraphBackground from "../images/biological-age-graph.svg";
import loadingIcon from '../images/3-dots-move-purple.svg';
import { BmrImage } from '../components/bmr-image';
import { ShareBanner } from '../components/share-banner';
import { useIsTruMe } from '../utils/white-label';
import useAuth from '../utils/useAuth';
import { fetchUserData } from '../redux/reducer/user';

const breakpoint = '1000px'; 

const Wrapper = styled(Column)`
    background: #FDFDFF;
`; 

const GraphBackground = styled.img`
    position: absolute;
    max-width: 80%;
    left: 0px;
    z-index: 0;

    @media (max-width: ${breakpoint}) {
        top: 60px;
    }

    @media (min-width: ${breakpoint}) {
        display: none;
    }
`;


const Background = () => <GraphBackground src={biologicalAgeGraphBackground} />

const Contents = styled(Column)`
    background: rgba(10, 19, 48, 1);

    @media (min-width: ${breakpoint}) {
        padding: 40px;
    }
`;

const ResultSubtitle = styled.div`
    color: white;
    text-align: center;
    font-size: 19px;
    font-family: 'Satoshi-Regular';

    @media (max-width: ${breakpoint}) {
        margin-top: 15px;
        text-align: center;
        text-align: justify;
    }
`;

const TopPanelWrapper = styled(Row)`
    padding: 70px;
    align-items: center;
    box-sizing: border-box;
    // min-height: 90vh;


    @media (max-width: ${breakpoint}) {
        flex-direction: column;
        padding: 50px 30px;
    }
`;

const ButtonsWrapper = styled(FlexRow)`
    margin-top: 40px;
    gap: 15px;
    justify-content: center;

    @media (max-width: 1100px) {
        justify-content: center;
        align-items: center;
    }
`;

const TopPanelButton = styled(Row)`
    cursor: pointer;
    white-space: nowrap;
    background: ${props => props.background ? props.background : 'transparent'};
    border: 1px solid white;
    border-radius: 100px;
    padding: 13px 24px;
    color: ${ props => props.color ? props.color : 'white'};
    cursor: pointer;
    align-items: center;

    img {
        margin-left: 10px;
        height: 15px;
    }
`; 


const DescriptionWrapper = styled(Column)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 100%;
    padding: 0 250px;
    gap: 10px;

    @media (max-width: 762px) {
      padding: 0;
    }
`;

const ResultText = styled.h1`
    color: white;
    font-family: 'Satoshi-Medium';
    font-size: 44px;
    text-align: left;
    text-align: center;

    span {
        color: #1EDCFF;
    }

    @media (max-width: 762px) {
        font-size: 32px;
        text-align: center;
        width: 100%;
        align-self: center;
    }
`;

const BottomPanelWrapper = styled(Column)`
 background: white;
 padding: 70px 100px;
 margin-top: -20px;
 border-radius: 20px 20px 0 0;
 gap: 20px;

 @media (max-width: ${breakpoint}) {
  padding: 50px 20px;
 }
`

const BottomPanelTitle = styled.h2`
 color: #051F73;
 font-family: 'Satoshi-Medium';
 font-size: 32px;
 font-style: normal;
 font-weight: 500;
 line-height: normal;
 align-self: center;

 @media (max-width: ${breakpoint}) {
  font-size: 24px;
 }
`

const BottomPanelSubTitle = styled.h3`
align-self: center;
 color: #5B637D;
 font-family: 'Satoshi-Regular';
 font-size: 19px;
 font-style: normal;
 font-weight: 500;
 line-height: normal;
 letter-spacing: -0.208px;
`

const FactorCard = styled.div`
 display: flex;
 flex-direction: column;
 border-radius: 16px;
 border: 1px solid #E2E2FF;
 background: #FDFDFF;
 padding: 24px;
 min-width: 300px;
 max-width: 300px;
 gap: 10px;

 @media (max-width: 762px) {
  width: 100%;
 }

 h3 {
  color: #051F73;
  font-family: 'Satoshi-Bold';
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
 }

 p {
  color: #5B637D;
  font-family: 'Satoshi-Regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  letter-spacing: -0.208px;
 }
`

const FactorCardGroup = styled(FlexRow)`
 gap: 20px;
 flex-wrap: wrap;
 justify-content: center;
 width: 100%;

  @media (max-width: 762px) {
    flex-direction: column;
    gap: 5px;
  }
`

const LoadingWrapper = styled(Column)`
    height: 80vh;
    align-items: center;
    justify-content: center;
`;

const LoadingIcon = styled.img`
  height: 45px;
  width: 45px;
  margin-left: 40px;
  margin-right: 40px;
`;

const Description  = styled(Column)`
    display: flex;
    width: 100%;
    text-align: center;
`

const BmrResultSpan = styled.h1`
  font-family: 'Satoshi-Medium';
  font-size: 80px;
  text-align: center;
  color: #1EDCFF;
`
const ReadMore = styled.span`
  display: none;
  @media (max-width: 768px) {
      display: block;
      color: #FFF;
      font-family: 'Satoshi-Medium';
      font-size: 20px;
      font-style: normal;
      font-weight: 900;
      line-height: 150%;
      letter-spacing: -0.208px;
  }
`

const TopPanel = ({ bmrResult }) => {
    const [shareBanner, setShareBanner] = useState(false);
    const [expand, setExpand] = useState(false);

    const { loading, isTrume } = useIsTruMe();
    
    const share = async () => {
        setShareBanner(true);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [shareBanner])

    return <Contents>
        <Background />
        <ShareBanner
            title={`My Basal Metabolic Rate is ${bmrResult}`}
            image={<BmrImage bmrResult={bmrResult}/>}
            url="https://trumelabs.com/calculate-my-bmr/"
            visible={shareBanner}
            setVisible={setShareBanner}
            onClose={() => setShareBanner(false)}
        />
        <TopPanelWrapper breakpoint='1100px'>
          <DescriptionWrapper>
            <ResultText>
              Your <span>Basal Metabolic Rate (BMR)</span> is:
            </ResultText>
            {bmrResult && <BmrResultSpan>{bmrResult}</BmrResultSpan>}
            <Description>
              <ResultSubtitle expand={expand}><span style={{ fontWeight: 'bold' }}>Understanding Your Basal Metabolic Rate (BMR) and Your Health: </span> Think of your body as an engine that runs 24/7. Your BMR is the energy (calories) your body needs to function at rest—powering breathing, blood flow, and temperature regulation.
              What controls how fast your engine burns fuel? Let’s explore the factors that shape your BMR and why it matters—not just for energy but for your biological age!
              </ResultSubtitle>
              <ButtonsWrapper>
                <TopPanelButton onClick={() => window.open('https://trumelabs.com/biological-age-test/','_blank')} background='white' color='rgba(5, 31, 115, 1)'>Order TruMe Test<img src={arrow} /></TopPanelButton>
                {(!loading && isTrume) && <TopPanelButton onClick={share}>Share Results<img src={shareIcon} /></TopPanelButton>}
              </ButtonsWrapper>
            </Description>
          </DescriptionWrapper>
        </TopPanelWrapper>
    </Contents>
}

const BottomPanel = () => {
 const factors = [
   {
     title: 'Age: The Slow and Steady Decline',
     description: 'Aging leads to muscle loss, which lowers BMR since muscle burns more calories than fat. This decline in BMR reduces energy needs and may signal metabolic aging, increasing the risk of weight gain and chronic diseases like diabetes and heart issues.'
   },
   {
     title: 'Gender: Men vs. Women',
     description: 'Women experience muscle loss due to hormonal changes, especially during menopause, which can lower BMR. Men can maintain a higher BMR by preserving muscle mass, helping prevent age-related metabolic slowdown.'
   },
   {
     title: 'Body Composition: Muscles vs. Fat',
     description: 'Aging leads to muscle loss, especially without strength training, reducing BMR and making weight gain easier. Preserving muscle mass helps slow biological aging and maintain a youthful metabolism.'
   },
   {
     title: 'Genetics: Your Inherited Metabolic Blueprint',
     description: 'While genetics can’t be changed, understanding your metabolic tendencies helps in making informed lifestyle choices. Those with a slower metabolism can focus on building muscle and optimizing their diet to prevent weight gain and metabolic issues.'
   },
   {
     title: 'Hormones: The Body’s Internal Regulators',
     description: 'Hormonal fluctuations with age can lower BMR. Women experience this during menopause as estrogen drops, while men may face muscle loss from declining testosterone. Maintaining hormonal balance supports a healthy metabolism and slows aging effects.'
   },
   {
     title: 'Physical Activity: More Movement = More Fuel',
     description: 'Regular exercise helps maintain or increase muscle mass, preventing the age-related decline in BMR. This supports a strong metabolism, reducing weight gain and preserving energy levels, key factors in slowing biological aging.'
   },
   {
     title: 'Diet: The Fuel You Provide',
     description: 'Poor nutrition can cause muscle loss, nutrient deficiencies, and metabolic slowdown, accelerating aging. A balanced diet with protein, healthy fats, and complex carbs supports muscle maintenance and keeps metabolism efficient, promoting a youthful biological age.'
   }
 ];

  return <BottomPanelWrapper>
   <BottomPanelTitle>Factors Affecting Basal Metabolic Rate (BMR)</BottomPanelTitle>
   <BottomPanelSubTitle>what influences how fast or slow your engine burns through this fuel?</BottomPanelSubTitle>
   <FactorCardGroup>
    {factors.map(factor => (
      <FactorCard key={factor.title}>
        <h3>{factor.title}</h3>
        <p>{factor.description}</p>
      </FactorCard>
    ))}
   </FactorCardGroup>
 </BottomPanelWrapper>
}


export const BmrResult = () => {
  const { userData, loading } = useSelector((s) => s.user);
  const { currentUser } = useAuth();
  const dispatch = useDispatch();
  useEffect(() => {
      if (currentUser) {
        dispatch(fetchUserData())
      };
  }, [currentUser, dispatch])

  if (loading) return <LoadingWrapper>
    <LoadingIcon src={loadingIcon} />
  </LoadingWrapper>

  return <Wrapper>
       <TopPanel bmrResult={userData?.bmr_result}/>
       <BottomPanel />
   </Wrapper>
}
