import styled from "styled-components"
import { Column, Row } from "../components/defaults";
import biologicalAgeGraphBackground from "../images/biological-age-graph.svg";
import biologicalAgeFlareTop from "../images/biological-age-flare-top.svg";
import biologicalAgeFlareBottom from "../images/biological-age-flare-bottom.svg";
import arrowIcon from "../images/arrow-white.svg";
import shareIcon from '../images/share.svg'
import { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import { useNavigate } from "react-router-dom";
import { ShareBanner } from "../components/share-banner";
import { PredictImage } from "../components/age-predict-image"
import { useIsTruMe } from "../utils/white-label";
import { AbsiShareImage } from "../components/absi-share-image";

const GraphBackground = styled.img`
    position: absolute;
    max-width: 60%;
    top: 0px;
    left: 0px;
    z-index: 0;
`;

const FlareTop = styled.img`
    position: absolute;
    top: -900px;
    left: 30px;
    z-index: 1;
`;

const FlareBottom = styled.img`
    position: absolute;
    left: -500px;
    bottom: -500px;
    z-index: 1;
`;

const Background = () => {

    return <>
        <GraphBackground src={biologicalAgeGraphBackground} />
        <FlareTop src={biologicalAgeFlareTop} />
        <FlareBottom src={biologicalAgeFlareBottom}/>
    </>

}

const AgePredictWrapper = styled(Column)`
    position: relative;
    min-height: 100vh;
    background: #0E112F;
    overflow: hidden;

    @media (max-width: 768px) {
        padding-left: 50px;
        padding-right: 50px;
    }
`;

const Contents = styled(Column)`
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 3;
    margin-bottom: 180px;
    margin-top: 180px;

    @media (max-width: 768px) {
        margin-top: 100px;
        margin-bottom: 120px;
    }
`;

const Title = styled.h1`
    color: white;
    text-align: center;
    font-size: 56px;
    font-family: 'Satoshi-Regular';
    letter-spacing: 0.01em;
    margin-bottom: 56px;

    @media (max-width: 768px) {
        font-size: 40px;
    }
`;

const ButtonIcon = styled.img`
    height: 15px;
    width: 15px;
`;

const Button = styled.div`
    background: #4D56F2;
    border-radius: 50px;
    color: white;
    height: 52px;
    min-width: 270px;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
    gap: 10px;
    font-size: 16px;

    ${props => props.secondary && `
        background: transparent;
        border: 1px solid white;    
    `}
`;

const Logo = styled.img`
    height: 35px;
    max-width: 300px
`;

const Subtitle = styled.p`
    margin-top: 0px;
    color: white;
    font-size: 21px;
    text-align: center;
    font-family: 'Satoshi-Medium';
    letter-spacing: 0.01em;
    max-width: 700px;

    @media (max-width: 768px) {
        font-size: 16px;
    }
`;

const LogosWrapper = styled(Row)`
    gap: 70px;
    margin-left: 70px;
`;

const MarqueeWrapper = styled.div`
    width: 100vw;

`;



const FeaturedIn = styled(Subtitle)`
    margin-top: 120px;
    margin-bottom: 40px;

    @media (max-width: 768px) {
        margin-top: 90px;
    }
`;

const Logos = () => {

    const [logos, setLogos] = useState([]);

    useEffect(() => {
        const importAll = (r) => {
            return r.keys().map((fileName) => ({
              name: fileName.replace("./", ""),
              src: r(fileName),
            }));
        };
        setLogos(importAll(require.context("../images/third-party-logos", false, /\.(svg)$/)));
    }, [])


    return <MarqueeWrapper>
        <Marquee speed={30}>
            <LogosWrapper>
                {logos.map((logo, index) => <Logo key={index} src={logo.src} />)}
            </LogosWrapper>
        </Marquee>
    </MarqueeWrapper>
}

export const AbsiCalculationLandingPage = () => {

    const [shareBannerVisible, setShareBannerVisible] = useState(false);
    const { loading, isTrume } = useIsTruMe();

    const navigate = useNavigate()

    useEffect(() => {
        window.scrollTo(0, 0);
        sessionStorage.removeItem('absiFormAnswers');
    }, [shareBannerVisible])

    const onStartNewTest = () => {
        navigate('/absi-calculation/form/1', { replace: true });
        window.parent.postMessage({ type: 'REDIRECT', url: `${window.location.origin}/absi-calculation/form/1` }, '*')
    }

    return <>
        <ShareBanner 
            title='Discover your Longevity Score'
            text="Discover your longevity score with our A Body Shape Index (ABSI) Calculator!"
            url="https://trumelabs.com/calculate-my-absi"
            image={<AbsiShareImage />}
            visible={shareBannerVisible} 
            setVisible={setShareBannerVisible}
        />
        <AgePredictWrapper>
            <Background />
            <Contents>
                <Title className='font-bold'>Longevity Score</Title>
                <Subtitle>Discover your Longevity Score with our A Body Shape Index (ABSI) Calculator!</Subtitle>
                <Button style={{ marginTop: 30, marginBottom: 10}} onClick={onStartNewTest}>
                    <span>Start the Test</span>
                    <ButtonIcon src={arrowIcon} />
                </Button>
                {!loading && isTrume && <Button onClick={() => setShareBannerVisible(true)} secondary>
                    <span>Share with loved ones</span>
                    <ButtonIcon src={shareIcon} />
                </Button>}
                <>
                    <FeaturedIn>Featured In:</FeaturedIn>
                    <Logos />
                </>
            </Contents>
        </AgePredictWrapper>
    </>

}