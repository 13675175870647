import { useEffect, useState } from 'react';
import { OnboardingForm } from '../components/onboarding-form.js';
import LandingPageFooter from '../components/landing-page-footer.js';
import { BMR_CALCULATION_FORM } from '../constants/bmr-calculation-form.js';
import { BmrShareImage } from '../components/bmr-share-image.js';
import { ShareBanner } from '../components/share-banner.js';
import { useNavigate } from 'react-router-dom';

export const BmrCalculation = () => {

  const [shareBannerVisible, setShareBannerVisible] = useState(false);
  const navigate = useNavigate();

  const onEndForm = (answers) => {
    sessionStorage.setItem('bmrFormAnswers', JSON.stringify(answers))
    navigate('/bmr-calculation/form/2', { replace: true });
  }

  return (<div>
      <ShareBanner 
        image={<BmrShareImage />}
        visible={shareBannerVisible} 
        setVisible={setShareBannerVisible}
      />
      <OnboardingForm 
        setShareBannerVisible={setShareBannerVisible}
        footer={<LandingPageFooter
          openShareModal={() => setShareBannerVisible(true)}
          title='Calorie Burn Calculator'
          description='Discover how many calories your body burns at rest with our Basal Metabolic Rate (BMR) Calculator!'
          shareButtonText='Share With Loved Ones'
          />
        }
        fields={BMR_CALCULATION_FORM}
        onEndForm={onEndForm}
      />
    </div>
  )
}