import { getAuth, signInWithCustomToken, signOut } from "firebase/auth"
import { useEffect, useRef } from "react"
import { NotificationManager } from 'react-notifications';
import { useNavigate } from "react-router-dom";

export const Impersonate = () => {

    const navigate = useNavigate();
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const redirect_from_brand = urlParams.get('redirect_from_brand');
    const notificationCount = useRef(0)
  
    useEffect(() => {
        if (redirect_from_brand) {
            if (notificationCount.current < 1) {
                notificationCount.current += 1;
                NotificationManager.success('Please use trumelabs.com page to check your data', 'We detected multiple brands associated with your account')
            }
        }
    }, [redirect_from_brand])
    
    const runImpersonation = async () => {
        try {
            await signOut(getAuth());
            await signInWithCustomToken(getAuth(), token)
            const newToken = await getAuth().currentUser.getIdToken();
            await localStorage.setItem('token', newToken);
        } catch (err) {
            NotificationManager.error('An Error Happened')
        }
        navigate('/sign-in');
    }

    useEffect(() => {
        if (token) runImpersonation()
    }, [token])

    return <div style={{ height: '100vh' }}>Loading</div>
    

}