import moment from 'moment'
import { useNavigate } from "react-router-dom";
import { NotificationManager } from 'react-notifications';
import { OnboardingForm } from "../../components/onboarding-form";
import { ONBOARDING_FORM } from "../../constants/onboarding-form";
import api from "../../utils/api";
import { getDateFormat, MOMENT_FORMAT } from '../../utils/date.js';


export const SignUpOnboardingDetails = () => {

    const navigate = useNavigate();

    const onEndForm = async (responses) => {
        let date_of_birth = moment(responses['date_of_birth'], getDateFormat(MOMENT_FORMAT))
        date_of_birth = date_of_birth.isValid() ? date_of_birth.toDate() : moment(responses['date_of_birth'])
        const payload = {
            ...responses,
            date_of_birth
        }
        try {
            await api.post('/user/demographic', payload);
            navigate('/dashboard')
        } catch (error) {
            console.error(error)
            NotificationManager.error('Error to predict the bio age. Try again later!')
        }
    }

    return <OnboardingForm
        fields={ONBOARDING_FORM}
        onEndForm={onEndForm}
    />

}