import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserData } from '../redux/reducer/user';
import useAuth from '../utils/useAuth';
import { AbsiCalculationLandingPage } from './absi-calculation-landing-page';
import { AbsiResult } from './absi-result';

export const AbsiCalculationRouter = () => {
 const { currentUser } = useAuth()
 const dispatch = useDispatch()
 const { userData } = useSelector((s) => s.user);

 useEffect(() => {
  if (currentUser) {
   dispatch(fetchUserData())
  };
 }, [currentUser])

 if (userData?.absi_result) return <AbsiResult />
 else return <AbsiCalculationLandingPage />
}