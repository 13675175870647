/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Column, FlexRow, Row } from '../components/defaults';
import styled from 'styled-components';
import arrow from '../images/arrow-navy.svg';
import shareIcon from '../images/share.svg';
import biologicalAgeGraphBackground from "../images/biological-age-graph.svg";
import loadingIcon from '../images/3-dots-move-purple.svg';
import { ShareBanner } from '../components/share-banner';
import { useIsTruMe } from '../utils/white-label';
import useAuth from '../utils/useAuth';
import { fetchUserData } from '../redux/reducer/user';
import { AbsiImage } from '../components/absi-result';

const breakpoint = '1000px'; 

const Wrapper = styled(Column)`
    background: #FDFDFF;
`; 

const GraphBackground = styled.img`
    position: absolute;
    max-width: 80%;
    left: 0px;
    z-index: 0;

    @media (max-width: ${breakpoint}) {
        top: 60px;
    }

    @media (min-width: ${breakpoint}) {
        display: none;
    }
`;


const Background = () => <GraphBackground src={biologicalAgeGraphBackground} />

const Contents = styled(Column)`
    background: linear-gradient(45deg, rgba(10, 19, 48, 1), rgb(55 97 162));

    @media (min-width: ${breakpoint}) {
        padding: 40px;
    }
`;

const ResultSubtitle = styled.div`
    color: white;
    max-width: 550px;
    text-align: center;
    align-self: center;
    font-size: 19px;
    font-family: 'Satoshi-Regular';

    @media (max-width: ${breakpoint}) {
        margin-top: 15px; 
    }
`;

const TopPanelWrapper = styled(Row)`
    padding: 70px;
    align-items: center;
    box-sizing: border-box;
    // min-height: 90vh;


    @media (max-width: ${breakpoint}) {
        flex-direction: column;
        padding: 50px 30px;
    }
`;

const ButtonsWrapper = styled(FlexRow)`
    margin-top: 40px;
    gap: 15px;
    justify-content: center;
    align-items: center;
`;

const TopPanelButton = styled(Row)`
    cursor: pointer;
    white-space: nowrap;
    background: ${props => props.background ? props.background : 'transparent'};
    border: 1px solid white;
    border-radius: 100px;
    padding: 13px 24px;
    color: ${ props => props.color ? props.color : 'white'};
    cursor: pointer;
    align-items: center;

    img {
        margin-left: 10px;
        height: 15px;
    }
`; 


const LeftColumn = styled(Column)`
    flex: 1;

`;


const ResultText = styled.div`
    color: white;
    font-family: 'Satoshi-Medium';
    font-size: 44px;
    display: flex;
    flex-direction: column;
    max-width: 566px;
    margin-bottom: 31px;
    align-self: center;
    text-align: center;

    span {
        color: #1EDCFF;
        font-size: 55px;
    }

    @media (max-width: 762px) {
        font-size: 32px;
        width: 100%;
    }
`;

const BottomPanelWrapper = styled(Column)`
 background: white;
 // width: 100%;
 padding: 70px 100px;
 margin-top: -20px;
 border-radius: 20px 20px 0 0;
 gap: 20px;

 @media (max-width: ${breakpoint}) {
  padding: 50px 20px;
 }
`

const BottomPanelTitle = styled.h2`
 color: #051F73;
 font-family: 'Satoshi-Medium';
 font-size: 32px;
 font-style: normal;
 font-weight: 500;
 line-height: normal;
 align-self: center;

 @media (max-width: ${breakpoint}) {
  font-size: 24px;
 }
`

const FactorCard = styled.div`
 display: flex;
 flex-direction: column;
 border-radius: 16px;
 border: 1px solid #E2E2FF;
 background: #FDFDFF;
 padding: 24px;
 max-width: 269px;
 gap: 10px;

 h3 {
  color: #051F73;
  font-family: 'Satoshi-Bold';
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
 }

 p {
  color: #5B637D;
  font-family: 'Satoshi-Regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  letter-spacing: -0.208px;
 }
`

const FactorCardGroup = styled(FlexRow)`
 gap: 20px;
 flex-wrap: wrap;
 margin-top: 30px;
 justify-content: center;

 @media (max-width: ${breakpoint}) {
  align-items: center;
 }

`

const LoadingWrapper = styled(Column)`
    height: 80vh;
    align-items: center;
    justify-content: center;
`;

const LoadingIcon = styled.img`
  height: 45px;
  width: 45px;
  margin-left: 40px;
  margin-right: 40px;
`;


const TopPanel = ({ absiResult }) => {
    const [shareBanner, setShareBanner] = useState(false);

    const { loading, isTrume } = useIsTruMe();
    
    const share = async () => {
        setShareBanner(true);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [shareBanner])

    absiResult = absiResult[0].toLocaleUpperCase() + absiResult.slice(1);

    return <Contents>
        <Background />
        <ShareBanner
            title={`My Longevity Score Rate is ${absiResult}`}
            image={<AbsiImage absiResult={absiResult}/>}
            url="https://trumelabs.com/calculate-my-absi/"
            visible={shareBanner}
            setVisible={setShareBanner}
            onClose={() => setShareBanner(false)}
        />
        <TopPanelWrapper breakpoint='1100px'>
            <LeftColumn>
               <ResultText>
                <div>Your Longevity Score is</div>
                <div><span>{absiResult}</span></div>
               </ResultText>
                   <ResultSubtitle>
                    <b>Your Waistline Predicts Your Health & Longevity. </b>
                    Belly fat isn’t just about looks—it impacts how long and how well you live. Your Longevity Score reveals the real health risks behind your waistline.
                    A high score means a greater risk of disease and faster aging. With TruMe BioAge, track how your lifestyle choices shape your future. Take control, slow aging, and live longer. Order your TruMe Test today!
                   </ResultSubtitle>
                   <ButtonsWrapper>
                       <TopPanelButton onClick={() => window.open('https://trumelabs.com/biological-age-test/','_blank')} background='white' color='rgba(5, 31, 115, 1)'>Order TruMe Test<img src={arrow} /></TopPanelButton>
                    {(!loading && isTrume) && <TopPanelButton onClick={share}>Share Results<img src={shareIcon} /></TopPanelButton>}
                </ButtonsWrapper>
            </LeftColumn>
        </TopPanelWrapper>
    </Contents>
}

const BottomPanel = () => {
 const factors = [
   {
     title: 'Belly Fat & Visceral Fat',
     description: 'Fat around your organs isn’t just weight—it speeds up aging and raises health risks. Processed foods and sugar pack on belly fat. Whole foods keep your waistline lean.'
   },
   {
     title: 'Muscle mass vs. fat mass',
     description: 'Higher muscle mass may increase weight without lowering your longevity score, whereas fat mass, especially around the abdomen, lowers your Longevity Score.'
   },
   {
     title: 'Exercise & Muscle Mass',
     description: 'More movement = better metabolism. Strength training + cardio keeps your metabolism and waistline in check.'
   },
   {
     title: 'Stress & Sleep',
     description: 'Poor sleep and high stress raise cortisol, making you store fat in your midsection.  Better sleep and lower cortisol mean a healthier, leaner body.'
   },
   {
     title: 'Alcohol Consumption',
     description: 'Excessive alcohol intake is associated with higher central fat accumulation ("beer belly").'
   },
   {
     title: 'Gut Health & Inflammation',
     description: 'Poor gut microbiome balance can lead to bloating and increased waist size.'
   },
   {
     title: 'Metabolism & Hormones',
     description: 'Slow metabolism and insulin resistance lead to lower Longevity Score and faster aging.'
   },
 ];

  return <BottomPanelWrapper>
   <BottomPanelTitle>Factors Affecting Longevity Score</BottomPanelTitle>
   <FactorCardGroup>
    {factors.map(factor => (
      <FactorCard key={factor.title}>
        <h3>{factor.title}</h3>
        <p>{factor.description}</p>
      </FactorCard>
    ))}
   </FactorCardGroup>

 </BottomPanelWrapper>
}


export const AbsiResult = () => {
  const { userData, loading } = useSelector((s) => s.user);
  const { currentUser } = useAuth();
  const dispatch = useDispatch();
  useEffect(() => {
      if (currentUser) {
        dispatch(fetchUserData())
      };
  }, [currentUser, dispatch]);



  if (loading) return <LoadingWrapper>
    <LoadingIcon src={loadingIcon} />
  </LoadingWrapper>

  return <Wrapper>
       <TopPanel absiResult={userData?.absi_result}/>
       <BottomPanel />
   </Wrapper>
}
