
import styled from "styled-components"
import { useLocation, Link } from "react-router-dom";
import { Row } from "./defaults";
import { MultibrandLogo } from "./multibrand-logo";

const Header = styled(Row)`
    height: 88px;
    background: white;
    align-items: center;
    padding-left: 24px;
    border-bottom: 1px solid rgba(0,0,0,.1);

    img {
        height: 64px;
    }

    @media (max-width: 768px) {
        height: 72px;
        
        img {
            height: 40px;
        }
    }

`

const LandingPageNavbar = () => {
  const userDashboard = useLocation().pathname.includes('dashboard');
  return (
    <>
      {!userDashboard && <Header>
          <Link to="https://trumelabs.com/">
            <MultibrandLogo className='w-[120px] h-[50px]' />
          </Link>
        </Header>}
    </>
  )
}

export default LandingPageNavbar