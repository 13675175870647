import React from 'react';
import styled from 'styled-components';
import { Column, Row } from './defaults';
import imgShare from '../images/img-share.svg';

const ImageWrapper = styled(Column)`
    height: 500px;
    width: 500px;
    background: rgba(2, 17, 39, 1);
    border-radius: 20px;
    position: relative;
    overflow: hidden;
`;

const BackgroundImg = styled.img`
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
`;

const ImageTitle = styled.div`
    color: white;
    font-size: 23px;
    font-family: 'Satoshi-Medium';
`;

const ImageAbsiWrapper = styled(Row)`
`;


const AbsiText = styled.div`
    background: -webkit-linear-gradient(180deg, #FFFFFF 17.8%, #A7AFFF 86.6%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`;

const Absi = styled(AbsiText)`
    color: white;
    font-size: 70px;
    margin-right: 5px;
    font-family: 'Satoshi-Regular';
`;

const AbsiImageWrapper = styled.div`
    transform: scale(0.7);
    transform-origin: top left;
`;

const Container = styled.div`
    height: 350px;
    width: 350px;
    overflow: hidden;
`;

const Contents = styled(Column)`
    align-items: center;
    justify-content: center;
    z-index: 1000;
    height: 100%;
`;

export const AbsiImage = React.forwardRef(({ absiResult }, ref) => {
    
    absiResult = absiResult[0].toLocaleUpperCase() + absiResult.slice(1);
    
    return (
        <Container>
        <AbsiImageWrapper>
            <ImageWrapper>
                <Contents>
                    <ImageTitle>My Longevity Score is</ImageTitle>
                    <ImageAbsiWrapper>
                        <Absi>{absiResult}</Absi>
                    </ImageAbsiWrapper>
                    <ImageTitle>Discover yours here!</ImageTitle>
                </Contents>
                <BackgroundImg src={imgShare} />
            </ImageWrapper>
        </AbsiImageWrapper>
        </Container>
    );
})

