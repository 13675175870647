/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { fetchUserData } from '../redux/reducer/user';

import { Column, Row } from '../components/defaults';
import { FolateVariant } from '../components/folate-panel'
import { LoadingMask } from '../components/loading-mask';
import Supplement from '../components/supplement.js'

import loadingIcon from '../images/grid.svg';
import welcomeCurve from '../images/welcome-curve.svg';
import pdfIcon from '../images/pdf.svg';
import shopIcon from '../images/shop.svg';
import downloadIcon from '../images/download.svg';
import { DnaIcon } from '../images/dna-icon.js';

import useAuth from '../utils/useAuth';
import { downloadPDF } from '../utils/downloader';
import api from '../utils/api';
import { useLocation } from 'react-router-dom';
import { SecondaryButton } from '../components/secondary-button.js';
import { Bundle } from '../components/genetic-dashboard/bundle.js';

const Wrapper = styled(Column)`
    background: #FDFDFF;
`;

const LoadingScreenWrapper = styled(Column)`
    align-items: center;
    justify-content: center;
    height: 80vh;
`;

const LoadingIcon = styled.img`
    height: 40px;
`;

const GeneticDashboardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 100%;
`

const SupplementsWrapper = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    background: #fff;
    margin: -25px 0px;
    box-shadow: rgba(79, 89, 240, 0.02) 0px 20px 24px 0px;
    border: 1px solid rgb(226, 226, 255);
    border-radius: 32px 32px 0 0;
    padding: 40px 56px;

    @media (max-width: 768px) {
        padding: 40px 25px;
    }
`

const SupplementsHeader = styled.div`
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

const Title = styled.div`
    color: #051F73;
    font-family: 'Satoshi-Medium';
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    @media (max-width: 768px) {
        font-size: 24px;
    }
`;

const Subtitle = styled.div`
  color: #5B637D;
    font-family: 'Satoshi-Medium';
    font-size: 19px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px; /* 189.474% */
    letter-spacing: -0.247px;

    @media (max-width: 768px) {
        font-size: 18px;
    }
`

const OverviewTitle = styled.div`
  color: #FFF;
  font-family: 'Satoshi-Medium';
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  @media (max-width: 768px) {
    font-size: 24px;
  }
`
const OverviewDescriptionWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

const OverviewDescription = styled.div`
  color: #FFF;
  font-family: 'Satoshi-Medium';
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 28.5px */
  letter-spacing: -0.247px;
  
  @media (max-width: 768px) {
    width: 100%;
    position: relative;
    line-height: 25px;
    max-height: ${(props) => props.expand ? 'none' : '6em'};
    display: ${(props) => props.expand ? 'block' : '-webkit-box; -webkit-line-clamp: 4; -webkit-box-orient: vertical'};
    overflow: ${(props) => props.expand ? 'visible' : 'hidden'};
    text-overflow: clip;
  }
`

const GeneticCards = styled.div`
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
`

const GeneticInstructions = styled.div`
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
`

const OrderNowButton = styled.button`
    display: flex;
    gap: 10px;
    color: #000;
    font-size: 18px;
    background: #fff;
    padding: 10px 15px;
    border-radius: 8px;
    border: 1px solid rgba(27, 31, 35, 0.15);;
    cursor: pointer;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    background-color: #FAFBFC;
    > div svg {
        stroke: #000;
    }
    &:hover {
        background: #051F73;
        color: #fff;
        & > div svg {
            stroke: #fff;
        }
    }
`

const Icon = styled.img``

const InstructionWrapper = styled.div`
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.30);
    background: rgba(0, 0, 0, 0.08);
    backdrop-filter: blur(20px);
    padding: 16px;
    flex-grow: 1;
`
const InstructionNumber = styled.span`
    color: #FFF;
    font-family: 'Satoshi-Medium';
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.306px;
`
const InstructionLabel = styled.span`
    color: #FFF;
    font-family: 'Satoshi-Medium';
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.306px;
`

const InstructionIconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    padding: 4px;
    background: ${(props) => props.background};
`

const InstructionBottomLeft = styled.span`
    color: #FFF;
    font-family: 'Satoshi-Medium';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`

const InstructionBottomRight = styled.span`
    color: #FFF;
    font-family: 'Satoshi-Medium';
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`

const SupplementsTabs = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`

const SupplementsContent = styled.div`
    max-width: 1700px;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
`

const SupplementsList = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-top: 40px;
    flex-wrap: wrap;
`



const OverviewWrapper = styled.div`
    display: flex;
    flex: 1;
    padding: 40px 56px 80px 56px;
    // margin: 10px 0px 0px 0px;
    // background-image: url(${welcomeCurve});
    justify-content: center;
    background: radial-gradient(
        circle at left bottom, 
        rgba(255, 255, 255, 0.8), 
        rgba(255, 255, 255, 0) 20%
        ),
        radial-gradient(
            circle at right top, 
            rgba(255, 255, 255, 0.8), 
            rgba(255, 255, 255, 0) 10%
            ),
        linear-gradient(to bottom right, #4f59f0, #7733ff);
    @media (max-width: 768px) {
        padding: 24px 24px 80px 24px;
    }
`;

const CardTitle = styled.div`
    display: flex;
    gap: 6px;
    width: fit-content;
    padding: 13px 16px;
    border-radius: 8px;
    background: ${props => props.background};
    color: ${props => props.color};
`

const DownloadButton = styled.button`
    display: flex;
    width: fit-content;
    align-items: center;
    gap: 24px;
    justify-content: space-between;
    border: 1px solid rgba(255, 255, 255, 0.30);
    border-radius: 106px;
    background: rgba(0, 0, 0, 0.08);
    backdrop-filter: blur(20px);
    padding: 16px 16px 16px 20px;
    cursor: pointer;

    @media (max-width: 768px) {
        width: 100%;
        padding: 8px;
    }
`

const DownloadIconWrapper = styled.div`
    background: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 56px;
    height: 56px;

    @media (max-width: 768px) {
        width: 40px;
        height: 40px;
    }
`

const DownloadButtonLeft = styled.div`
    display: flex;
    width: fit-content;
    flex: 1;
    align-items: center;
    gap: 11px;
    color: #FFF;
    font-family: 'Satoshi-Regular';
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.247px;

    img {
        width: 24px;
    }
`

const OverviewHighlight = styled.span`
    color: #FFCE1E;
    font-family: 'Satoshi-Medium';
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: -0.247px;
`

const OverviewColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 50%;

    @media (max-width: 768px) {
        width: 100%;
        gap: 26px;
    }
`

const OverviewFlexRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 1700px;
    gap: 54px;

    @media (max-width: ${props => props.breakpoint || '762px'}) {
        flex-direction: column;
        gap: 28px;
    }
`;

const ReadMore = styled.span`
    display: none;
    @media (max-width: 768px) {
        display: block;
        color: #FFF;
        font-family: 'Satoshi-Medium';
        font-size: 20px;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        letter-spacing: -0.208px;
    }
`

const LoadingScreen = () => {
    return <LoadingScreenWrapper>
        <LoadingIcon src={loadingIcon} />
    </LoadingScreenWrapper>
}

const instructionsCopy = {
    green: {
      mutations: 0,
      functioning: '100%',
      iconColorWrapper: '#B8FFB6',
      iconColor: 'rgba(29, 106, 28, 1)'
    },
    yellow: {
      mutations: 1,
      functioning: '~40%',
      iconColorWrapper: '#FFF1BF',
      iconColor: 'rgba(176, 97, 0, 1)'
    },
    red: {
      mutations: 2,
      functioning: '0 - 10%',
      iconColorWrapper: '#FFE6E9',
      iconColor: 'rgba(255, 0, 0, 1)'
    },
}
  


const Instructions = ({ color }) => {
    const {
        mutations,
        functioning,
        iconColorWrapper,
        iconColor
    } = instructionsCopy[color];
    
    return <InstructionWrapper>
        <Row style={{ flex: 1, gap: '45px'}}>
            <Column style={{ gap: '11px', width: '100%' }}>
                <Row style={{ gap: '8px' }}>
                    <InstructionNumber>{mutations}</InstructionNumber>
                    <InstructionLabel>risk variants</InstructionLabel>
                </Row>
                <Row style={{ gap: '8px' }}>
                    <InstructionNumber>{mutations}</InstructionNumber>
                    <InstructionLabel>Mutations</InstructionLabel>
                </Row>
            </Column>
            <Column>
                <InstructionIconWrapper background={iconColorWrapper}>
                    <DnaIcon fill={iconColor} />
                </InstructionIconWrapper>
            </Column>
        </Row>

        <Row style={{ marginTop: '20px', justifyContent: 'space-between', alignItems: 'end' }}>
            <InstructionBottomLeft>
                % functioning
            </InstructionBottomLeft>
            <InstructionBottomRight>
                {functioning}
            </InstructionBottomRight>
        </Row>
    </InstructionWrapper>
}

const SupplementsData = ({ geneticData }) => {
    const { hash } = useLocation();
    const [productSelection, setProductionSelection] = useState('bundle')

    useEffect(() => {
        if (hash === '#recommended') {
            document.getElementById('recommended').scrollIntoView();
        }
    }, [hash]);

    return <SupplementsWrapper>
        <SupplementsContent>
            <CardTitle background="rgba(245, 246, 250, 1)" color="#051F73" >
                <Icon src={shopIcon} />
                Shop
            </CardTitle>
            <SupplementsHeader>
                <Title>Recommended just for you.</Title>
                <Subtitle>{geneticData['recommended_set']['subtitle']}.</Subtitle>
            </SupplementsHeader>
            <SupplementsTabs>
                <SecondaryButton active={productSelection === 'bundle'} onClick={() => setProductionSelection('bundle')}>
                    Add All to your Amazon Cart
                </SecondaryButton>

                <SecondaryButton active={productSelection === 'all'} onClick={() => setProductionSelection('all')}>
                    Individual supplements
                </SecondaryButton>
            </SupplementsTabs>
            {productSelection == 'bundle' && geneticData['recommended_set'] && <SupplementsList>
                <Bundle
                    supplementsLink={geneticData['recommended_set']['supplements_link']}
                    supplementsList={geneticData['recommended_set']['supplements']}
                />
            </SupplementsList>}
            {productSelection == 'all' && <SupplementsList>
                {geneticData['recommended_set']['supplements'].map(supplement => (
                    <Supplement supplement={supplement} />
                ))}
            </SupplementsList>}
            
        </SupplementsContent>
    </SupplementsWrapper>
}

const Overview = ({ geneticData }) => {
    const [ loading, setLoading ] = useState(false)
    const [expand, setExpand] = useState(false)

    const openPdf = async () => {
        setLoading(true)
        try {
            const res = await api.get(`/reports?barcode=${geneticData.barcode}&type=${geneticData.test_types[0].name}`);
            if (!res.data.url) NotificationManager.success('Your report is being processed. Please check back later.')
            else await downloadPDF(res.data.url);
        } catch (err) {
            
        }
        setLoading(false);
    }

    const getOverview = () => {
        const overviewParts = geneticData['recommended_set']['overview'].split(geneticData['recommended_set']['genes_text'])
        return (
            <>
                {overviewParts[0]}
                <OverviewHighlight>
                    {geneticData['recommended_set']['genes_text']}
                </OverviewHighlight>
                {overviewParts[1]}
            </>
        )
    }

    return (
        <OverviewWrapper>
            {loading && <LoadingMask />}
            <OverviewFlexRow>
                <OverviewColumn>
                    <CardTitle background="rgba(255, 255, 255, 0.07)" color="#fff" >
                        <DnaIcon fill='#fff' />
                        DNA Kit: #{geneticData.barcode}
                    </CardTitle>
                    <OverviewTitle>Overview</OverviewTitle>
                    <OverviewDescriptionWrapper>
                        <OverviewDescription expand={expand}>{getOverview()}</OverviewDescription>
                        <ReadMore onClick={()=> setExpand(prevValue => !prevValue)}>
                            {expand ? 'Read less' : 'Read more...'}
                        </ReadMore>
                    </OverviewDescriptionWrapper>
                    <DownloadButton onClick={() => openPdf()}>
                        <DownloadButtonLeft>
                            <Icon src={pdfIcon} />
                            View Full Report
                        </DownloadButtonLeft>
                        <DownloadIconWrapper>
                            <Icon src={downloadIcon} />
                        </DownloadIconWrapper>
                    </DownloadButton>
                </OverviewColumn>
                <OverviewColumn>
                    <GeneticCards>
                        <FolateVariant name='COMT'/>
                        <FolateVariant name='AHCY'/>
                        <FolateVariant name='MTRR'/>
                        <FolateVariant name='MTR'/>
                        <FolateVariant name='MTHFR'/>
                    </GeneticCards>
                    <GeneticInstructions>
                        <Instructions color='green' />
                        <Instructions color='yellow' />
                        <Instructions color='red' />
                    </GeneticInstructions>
                </OverviewColumn>
            </OverviewFlexRow>
        </OverviewWrapper>
    )
}


export const GeneticDashboard = () => {

    const dispatch = useDispatch();
    const [ forceLoading, setForceLoading ] = useState(true)
    const { currentUser, isLoading } = useAuth()
    const { userData, loading } = useSelector((s) => s.user)
    const dataAvailable = !loading && Object.keys(userData).length > 0

    useEffect(() => {
        if (currentUser && !dataAvailable) {
            dispatch(fetchUserData())
        };
        if (dataAvailable) { setForceLoading(false) }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser, dataAvailable])

    const geneticData = useSelector((state) => state.user.userData.genetic_kit);

    if (!geneticData?.data) return <div />

    return <Wrapper>
        {forceLoading || isLoading ? <LoadingScreen /> : 
        <GeneticDashboardWrapper>
            <Overview geneticData={geneticData} />
            { geneticData?.recommended_set &&  <SupplementsData geneticData={geneticData} /> }
        </GeneticDashboardWrapper>}
    </Wrapper>
}