import { getDateFormat, PLACEHOLDER_FORMAT } from "../utils/date"

const DATE_FORMAT = getDateFormat();

const PLACEHOLDER_DATE_FORMAT = getDateFormat(PLACEHOLDER_FORMAT);

export const PREDICT_AGE_FORM = [
    {
      description: 'Tell us a little bit about yourself',
      question: 'My birthday is',
      type: 'date',
      dateFormat: DATE_FORMAT,
      placeholder: PLACEHOLDER_DATE_FORMAT,
      name: 'date_of_birth',
      inputMode: 'numeric',
      askOnlyOnce: true,
    },
    {
      description: 'Tell us a little bit about yourself',
      question: 'My height is',
      type: 'dropbox',
      name: 'height',
      renderInternalQuestion: true,
      inputMode: 'numeric',
      options: [
        {
          label: 'ft.',
          inputs: [
            {
              placeholder: '00',
              legend: 'ft',
              name: 'height_feet',
              inputMode: 'numeric',
              type: 'number',
              minValue: 0
            },
            {
              placeholder: '00',
              legend: 'in',
              name: 'height_inches',
              inputMode: 'numeric',
              type: 'number',
              minValue: 0
            },
          ]
        },
        {
          label: 'cm',
          inputs: [
            {
              placeholder: '00',
              legend: 'cm',
              name: 'height_centimeter',
              inputMode: 'numeric',
              type: 'number',
            }
          ]
        }
      ]
    },
    {
      description: 'Tell us a little bit about yourself',
      question: 'I weigh',
      type: 'dropbox',
      name: 'weight',
      renderInternalQuestion: true,
      inputMode: 'numeric',
      options: [
        {
          label: 'lbs.',
          inputs: [
            {
              placeholder: '0',
              legend: 'lbs',
              name: 'weight_pounds',
              inputMode: 'numeric',
              type: 'number',
            },
          ]
        },
        {
          label: 'kg',
          inputs: [
            {
              placeholder: '0',
              legend: 'kg',
              name: 'weight_kilos',
              inputMode: 'numeric',
              type: 'number',
            }
          ]
        }
      ]
    },
    {
      description: 'Let’s dive into some of your habits.',
      question: 'Do you drink coffee?  ☕️',
      type: 'selector',
      name: 'coffee_consumption',
      optionsType: 'yes_no',
      autoSubmit: true,
      options: [
        {
          label: 'Yes'
        },
        {
          label: 'No'
        }
      ]
    },
    {
      description: 'Let’s dive into some of your habits.',
      question: 'Do you Smoke?  🚬',
      type: 'selector',
      name: 'tobacco_consumption',
      optionsType: 'yes_no',
      autoSubmit: true,
      options: [
        {
          label: 'Yes'
        },
        {
          label: 'No'
        }
      ]
    },
    {
      description: 'Let’s dive into some of your habits.',
      question: 'Do you wake up rested?  🥱',
      type: 'selector',
      name: 'waking_up_condition',
      optionsType: 'yes_no',
      autoSubmit: true,
      options: [
        {
          label: 'Yes'
        },
        {
          label: 'No'
        }
      ]
    },
    {
      description: 'Let’s dive into some of your habits.',
      question: 'Do you have trouble sleeping  💤',
      type: 'selector',
      name: 'trouble_sleeping',
      optionsType: 'yes_no',
      autoSubmit: true,
      options: [
        {
          label: 'Yes'
        },
        {
          label: 'No'
        }
      ]
    },
    {
      description: 'Let’s dive into some of your habits.',
      question: 'On average, how many hours do you sleep per night?  🛌',
      type: 'selector',
      name: 'sleep_hours',
      autoSubmit: true,
      options: [
        {
          label: '5 or less'
        },
        {
          label: '6'
        },
        {
          label: '7'
        },
        {
          label: '8'
        },
        {
          label: '9'
        },
        {
          label: '10 or more'
        },
      ]
    },
    {
      description: 'Let’s dive into some of your habits.',
      question: 'On average, how many days per week do you exercise?  🏋',
      type: 'selector',
      name: 'physical_activity_days',
      autoSubmit: true,
      options: [
        {
          label: '1'
        },
        {
          label: '2'
        },
        {
          label: '3'
        },
        {
          label: '4'
        },
        {
          label: '5'
        },
        {
          label: '6'
        },
        {
          label: '7'
        },
      ]
    },
    {
      description: 'We’re almost there! just a couple more questions.',
      question: 'Have you been diagnosed with cancer?  🩺',
      type: 'selector',
      name: 'has_cancer',
      optionsType: 'yes_no',
      autoSubmit: true,
      options: [
        {
          label: 'Yes'
        },
        {
          label: 'No'
        }
      ]
    },
    {
      description: 'We’re almost there! just a couple more questions.',
      question: 'Have you been diagnosed with diabetes?  🩺',
      type: 'selector',
      name: 'has_diabetes',
      optionsType: 'yes_no',
      autoSubmit: true,
      options: [
        {
          label: 'Yes'
        },
        {
          label: 'No'
        }
      ]
    },
]