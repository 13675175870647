import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import { ShareIcon } from '../images/share-icon.js'
import { ArrowLeftIcon } from '../images/arrow-left-icon.js'
import { ShareBanner } from '../components/share-banner.js';
import { PredictImage } from '../components/age-predict-image.js';
import LandingPageNavbar from '../components/landing-page-navbar.js';
import { ProgressBar } from '../components/progress-bar.js';
import LandingPageUserForm from '../components/landing-page-user-form.js'
import LandingPageFooter from '../components/landing-page-footer.js'
import { calculateAge } from "../utils/age.js"
import { getAuth, signInWithEmailAndPassword } from "firebase/auth"
import useAuth from '../utils/useAuth.js'
import { fetchUserData } from '../redux/reducer/user.js'
import { useFirebaseWarningRemove } from '../utils/firebase.js';
import { getDateFormat, MOMENT_FORMAT } from '../utils/date.js';
import { normalizeUnitAnswers } from '../utils/units.js';
import styled from "styled-components"
import moment from 'moment'
import api from "../utils/api.js";


const ProgressBarWrapper = styled.div`
  background: blue;
`

const Wrapper = styled.div`
  overflow: 'auto';
  position: relative;
  padding: ${props => props.userForm ?  '10px 10px 0px 0px' : '0'};
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: start;

   @media (max-width: 768px) {
      padding: ${props => props.userForm ?  '10px 10px' : '0px 10px 40px 10px'};
      flex: none;
   }
`

const FieldHeaderMobile = ({
  setShareBannerVisible,
  onPreviousQuestion,
}) => {

  return <FieldHeaderMobileWrapper>
      <QuestionButton onClick={onPreviousQuestion}>
        <ArrowLeftIcon fill="#5B637D" />
      </QuestionButton>
      {setShareBannerVisible && <QuestionButton onClick={() => setShareBannerVisible(true)}>
        <ShareIcon fill="#02145E" />
      </QuestionButton>}
  </FieldHeaderMobileWrapper>


}

const QuestionButton = styled.button`
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media (max-width: 768px) {
    border-radius: 100%;
    border: 1px solid #D9D9D9;
    width: 40px;
    height: 40px;
  }
`

const FieldHeaderMobileWrapper = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    padding: 15px;
    justify-content: space-between;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
  }
`

function convertInchesToCm(inches) {
  const conversionFactor = 2.54;
  return inches * conversionFactor;
}

export const AbsiCalculationRegistrationForm = () => {
  const [shareBannerVisible, setShareBannerVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const auth = getAuth()
  const { currentUser } = useAuth()
  const dispatch = useDispatch()
  useFirebaseWarningRemove();
  const REDIRECT_URL = '/sign-in?redirect_lead_page=/absi-calculation/form/2'

  useEffect(() => {
    let responses = sessionStorage.getItem('absiFormAnswers')
    if (!responses) navigate(-1, { replace: true })
  }, []);
  
  
  useEffect(() => {
      if (currentUser) {
          dispatch(fetchUserData())
      };
  }, [currentUser])

  const userData = useSelector((state) => state.user.userData);

  const convertHeightFeetInchesToCm = (height_feet, height_inches) => {
    const heightInches = parseInt(height_feet) * 12 + parseInt(height_inches)
    return Math.round(heightInches * 2.54)
  }

  const convertWeightPoundsToKilos = (weight) => {
    return Math.round(weight * 0.453592)
  }

  const onSubmit = async (data) => {
    setIsLoading(true)
    try {
      let prevResponses = sessionStorage.getItem('absiFormAnswers')
      prevResponses = JSON.parse(prevResponses);
      prevResponses = normalizeUnitAnswers(prevResponses)

      let {
        date_of_birth,
        height_feet,
        height_inches,
        weight,
        waist_inches,
        waist_centimeter,
        biological_sex
      } = prevResponses;


      date_of_birth = moment(date_of_birth, getDateFormat(MOMENT_FORMAT))
      date_of_birth = date_of_birth.isValid() ?
        date_of_birth :
        moment(prevResponses['date_of_birth'])

      moment().toDate()
      const payload = {
        user_data: {
          'first_name': data.firstName,
          'last_name': data.lastName,
          'email': data.email,
          'password': data.password,
          'terms_accepted': data.termsAccepted,
          date_of_birth: date_of_birth.toDate(),
        },
        survey_data: {
          waist: waist_centimeter ? waist_centimeter : convertInchesToCm(waist_inches),
          height: convertHeightFeetInchesToCm(height_feet, height_inches),
          weight_kilos: convertWeightPoundsToKilos(weight),
          age: calculateAge(date_of_birth, getDateFormat(MOMENT_FORMAT)),
          biological_sex
        }
      }
      const result = await api.post('/calculate-absi', payload);

      if (result?.data['absi_result']) {
        if (data.password) {
          await signInWithEmailAndPassword(auth, data.email, data.password);
          const token = await auth.currentUser.getIdToken();
          localStorage.setItem('token', token);
        }
        navigate('/dashboard/absi')
        sessionStorage.removeItem('absiFormAnswers')
      }
    } catch (error) {
      console.log(error)
      if (error.response?.data?.title?.includes('user already exists')) {
        NotificationManager.info('User already exists. Please sign in.')
        navigate(REDIRECT_URL, { replace: true })
        return
      }
      NotificationManager.error('Error to predict the bio age. Try again later!')
    } finally {
      setIsLoading(false)
    }
  }

  const previousQuestion = () => {
    navigate(-1);
  }

  return (
    <>
      <ShareBanner 
        image={<PredictImage />}
        visible={shareBannerVisible} 
        setVisible={setShareBannerVisible}
      />
      <LandingPageNavbar />
      <ProgressBarWrapper>
        <ProgressBar percent={100} width='100%' background="linear-gradient(271deg, #4F59F0 -3.59%, #B5EDFF 92.5%)" />
      </ProgressBarWrapper>
      <Wrapper> 
        <FieldHeaderMobile 
          setShareBannerVisible={setShareBannerVisible}
          onPreviousQuestion={previousQuestion}
        />
        <LandingPageUserForm
          onSubmit={onSubmit}
          isLoading={isLoading}
          userData={userData}
          redirectUrl={REDIRECT_URL}
        />
      </Wrapper>
      <LandingPageFooter
        openShareModal={() => setShareBannerVisible(true)}
        title='absi Calculator'
        subTitle='Unlock Your Body’s Energy Needs!'
        description='Discover how many calories your body burns at rest with our Basal Metabolic Rate (absi) Calculator!'
        shareButtonText='Share With Loved Ones'
      />
    </>
  )
}