import styled,  { keyframes } from "styled-components"
import { useForm } from "react-hook-form"
import arrowRight from '../images/arrow-right.svg'
import { AcceptTerms } from "./accept-terms"
import { useRef, useState } from "react"
import { Column } from "./defaults"
import { moveToNextFocusable } from "../utils/input"


const Icon = styled.img``

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 50px 30%;
  padding-bottom: 80px;
  gap: 20px;

  @media (max-width: 768px) {
    padding: 40px 34px;
  }
`;

const FormFilledWrapper = styled(Column)`
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FormTitle = styled.h1`
  color: #051F73;
  text-align: center;
  font-family: 'Satoshi-Medium';
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center
`

const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 26px;
  justify-content: center;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const FormColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
`

const FormInputComponent = styled.input`
  padding: 0px 10px;
  height: 56px;
  align-self: stretch;
  border-radius: 8px;
  border: solid 1px #DBDBDB;
  color: #051F73;
  font-family: 'Satoshi-Medium';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.112px;
`

const FormInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  // min-width: 200px;
  width: 100%;
`

const FormInputLabel = styled.div`
  color: #5B637D;
  font-family: 'Satoshi-Medium';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.112px;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  width: 16px;
  height: 16px;
  border: 2px solid transparent;
  border-top-color: white;
  border-radius: 50%;
  animation: ${rotate} 0.8s linear infinite;
  margin-right: 8px;
`;


const FormButton = styled.button`
  display: flex;
  padding: 13px 44px 16px 42px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 28px;
  background: #4D56F2;
  border: none;
  font-family: 'Satoshi-Bold';
  font-size: 16px;
  text-wrap: nowrap;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0;
  color: #fff;
  width: 80%;
  align-self: center;
  cursor: pointer;
  overflow: hidden;  

  opacity: ${props => props.disabled ? 0.5 : 1};
  pointer-events: ${props => props.disabled ? 'none' : 'initial'};

  @media (max-width: 768px) {
    width: 100%;
  }
`

const FormLabel = styled.span`
  color: #5B637D;
  font-family: 'Satoshi-Medium';
  font-size: 19px;
  font-style: normal;
  font-weight: normal;
  line-height: 36px;
  letter-spacing: 0.306px;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`

const SignIn = styled.a`
  color: #4F59F0;
  font-family: 'Satoshi-Bold';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.112px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
`

const FormFilled = ({
  userData,
  onSubmit,
  isLoading
}) => {

  return <FormFilledWrapper>
    <FormTitle>Hi, {userData.first_name}!</FormTitle>
    <FormButton style={{ marginTop: 30 }} onClick={() => onSubmit({ email: userData?.contact?.email })} disabled={isLoading}>
      { isLoading ? "We're processing your results..." : 'Get Results' }
      { isLoading ? <Spinner /> : <Icon src={arrowRight} /> }
    </FormButton>
  </FormFilledWrapper> 

}

const FormInput = ({
  submitAction = () => {},
  formRef,
  formValidation,
  ...props
}) => {

  const selfRef = useRef();

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      moveToNextFocusable(formRef.current, e, submitAction, { selector: 'input' })
    }
  }

  return <FormInputComponent 
    {...props}
    ref={selfRef}
    onKeyDown={onKeyDown}
    {...formValidation}
  />

}

const LandingPageUserForm = ({ onSubmit, isLoading, userData, redirectUrl }) => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [termsAccepted, setTermsAccepted] = useState(false);
  const onSubmitForm = data => onSubmit({  ...data, termsAccepted });
  const formRef = useRef();
  return (
    <FormWrapper>
      {
        userData?.first_name ? 
          <FormFilled userData={userData} onSubmit={onSubmit} isLoading={isLoading} /> :
          <>
            <FormLabel>Last thing!</FormLabel>
            <FormTitle>Quickly Create a Trume account to get your results!</FormTitle>
            <Form ref={formRef} onSubmit={handleSubmit(onSubmitForm)}>
              <FormRow>
                <FormInputWrapper>
                  <FormInputLabel>First Name</FormInputLabel>
                  <FormInput
                    type="text"
                    formRef={formRef}
                    formValidation={register('firstName', { required: true })}
                  />
                </FormInputWrapper>
                <FormInputWrapper>
                  <FormInputLabel>Last Name</FormInputLabel>
                  <FormInput
                    type="text"
                    formRef={formRef}
                    formValidation={register('lastName', { required: true })}
                  />
                </FormInputWrapper>
              </FormRow>
              <FormColumn>
                <FormInputWrapper>
                  <FormInputLabel>Email</FormInputLabel>
                  <FormInput
                    type="email"
                    formRef={formRef}
                    formValidation={register('email', { required: true })}
                  />
                </FormInputWrapper>

                <FormInputWrapper>
                  <FormInputLabel>Password</FormInputLabel>
                  <FormInput
                    type="password"
                    formRef={formRef}
                    formValidation={register('password', { required: true })}
                  />
                </FormInputWrapper>
              </FormColumn>

              <AcceptTerms
                accepted={termsAccepted}
                setAccepted={setTermsAccepted}
              />

              <SignIn style={{ color: 'blue' }} href={redirectUrl}>Already have an account?</SignIn>

              <FormButton type="submit" style={{ marginTop: 20 }} disabled={isLoading || !termsAccepted}>
                { isLoading ? "We're processing your results..." : 'Get Results' }
                { isLoading ? <Spinner /> : <Icon src={arrowRight} /> }
              </FormButton>
            </Form>
          </>
        } 
      </FormWrapper>
  )
}

export default LandingPageUserForm