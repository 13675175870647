import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserData } from '../redux/reducer/user';
import useAuth from '../utils/useAuth';
import { BmrCalculationLandingPage } from './bmr-calculation-landing-page'
import { BmrResult } from './bmr-result'

export const BmrCalculationRouter = () => {
 const { currentUser } = useAuth()
 const dispatch = useDispatch()
 const { userData } = useSelector((s) => s.user);

 useEffect(() => {
  if (currentUser) {
   dispatch(fetchUserData())
  };
 }, [currentUser])

 if (userData?.bmr_result) return <BmrResult />
 else return <BmrCalculationLandingPage />
}